module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<span><%= text.variations %>:<span>\n<span>\n<% animationClassDefs.forEach(function (animationClassDef) { %>\n\t<a class=\"animation-links__link link <%= animationClassDef.id === currentAnimationId ? 'link--current' : '' %>\" data-animation-id=\"<%= animationClassDef.id %>\"><%= animationClassDef.title %></a>\n<% }); %>\n</span>\n"
  , __filename = "src/js/templates/animationLinks.ejs";
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<span>")
    ; __append(escapeFn( text.variations ))
    ; __append(":<span>\n<span>\n")
    ; __line = 3
    ;  animationClassDefs.forEach(function (animationClassDef) { 
    ; __append("\n	<a class=\"animation-links__link link ")
    ; __line = 4
    ; __append(escapeFn( animationClassDef.id === currentAnimationId ? 'link--current' : '' ))
    ; __append("\" data-animation-id=\"")
    ; __append(escapeFn( animationClassDef.id ))
    ; __append("\">")
    ; __append(escapeFn( animationClassDef.title ))
    ; __append("</a>\n")
    ; __line = 5
    ;  }); 
    ; __append("\n</span>\n")
    ; __line = 7
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

//# sourceURL=src/js/templates/animationLinks.ejs

}