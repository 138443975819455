module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<% languages.forEach(function (lang) { %>\n\t<a class=\"lang-links__link link <%= lang.isCurrent ? 'link--current' : '' %>\" href=\"<%= lang.url %>\"><%= lang.title %></a>\n<% }); %>\n"
  , __filename = "src/js/templates/langLinks.ejs";
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  languages.forEach(function (lang) { 
    ; __append("\n	<a class=\"lang-links__link link ")
    ; __line = 2
    ; __append(escapeFn( lang.isCurrent ? 'link--current' : '' ))
    ; __append("\" href=\"")
    ; __append(escapeFn( lang.url ))
    ; __append("\">")
    ; __append(escapeFn( lang.title ))
    ; __append("</a>\n")
    ; __line = 3
    ;  }); 
    ; __append("\n")
    ; __line = 4
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

//# sourceURL=src/js/templates/langLinks.ejs

}